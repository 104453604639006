import { Fragment, FunctionComponent } from "react";
import NavBar from "./NavBar";

type LayoutProps = {};

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <Fragment>
      <NavBar />
      {children}
    </Fragment>
  );
};

export default Layout;
