import { FunctionComponent, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSession, signOut } from "next-auth/client";

type NavBarProps = {};

const ProfileDropDown = ({ isOpen }) => {
  return isOpen ? (
    <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
      <div
        className="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
      >
        <a
          href="#"
          className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
        >
          Your Profile
        </a>
        <a
          href="#"
          className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
        >
          Settings
        </a>
        <a
          href="#"
          className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
        >
          Sign out
        </a>
      </div>
    </div>
  ) : null;
};

const NavBar: FunctionComponent<NavBarProps> = () => {
  const router = useRouter();
  const [session, loading] = useSession();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  if (!session) return null;

  const inactiveClass =
    "ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out";
  const activeClass =
    "ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-indigo-500 text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-indigo-700 transition duration-150 ease-in-out";

  return (
    <nav className="bg-white shadow sticky top-0 z-40">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/*<!-- Mobile menu button -->*/}
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              aria-label="Main menu"
              aria-expanded="false"
            >
              {/*<!-- Icon when menu is closed. -->*/}
              {/*<!-- Menu open: "hidden", Menu closed: "block" -->*/}
              <svg
                className="block h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/*<!-- Icon when menu is open. -->*/}
              {/*<!-- Menu open: "block", Menu closed: "hidden" -->*/}
              <svg
                className="hidden h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <img
                className="block lg:hidden h-8 w-auto"
                src="/logo.svg"
                alt="Workflow logo"
              />
              <img
                className="hidden lg:block h-8 w-auto"
                src="/logo.svg"
                alt="Workflow logo"
              />
            </div>
            <div className="hidden sm:ml-6 sm:flex">
              <Link href="/">
                <a
                  className={
                    router.pathname === "/" ? activeClass : inactiveClass
                  }
                >
                  Home
                </a>
              </Link>
              <Link href="/panel/orders">
                <a
                  className={
                    router.pathname.startsWith("/panel/orders")
                      ? activeClass
                      : inactiveClass
                  }
                >
                  Orders
                </a>
              </Link>
              {session.role === "ADMIN" ? (
                <Link href="/panel/services">
                  <a
                    className={
                      router.pathname.startsWith("/panel/services")
                        ? activeClass
                        : inactiveClass
                    }
                  >
                    Services
                  </a>
                </Link>
              ) : null}
              {session.role === "ADMIN" ? (
                <Link href="/panel/pages">
                  <a
                    className={
                      router.pathname.startsWith("/panel/pages")
                        ? activeClass
                        : inactiveClass
                    }
                  >
                    Pages
                  </a>
                </Link>
              ) : null}
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <a className={inactiveClass} href="/" onClick={() => signOut()}>
              Sign Out
            </a>
          </div>
        </div>
      </div>

      {/*<!--
        Mobile menu, toggle classes based on menu state.
        Menu open: "block", Menu closed: "hidden"
      -->*/}
      <div className="hidden sm:hidden">
        <div className="pt-2 pb-4">
          <a
            href="#"
            className="block pl-3 pr-4 py-2 border-l-4 border-indigo-500 text-base font-medium text-indigo-700 bg-indigo-50 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
          >
            Dashboard
          </a>
          <a
            href="#"
            className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
          >
            Team
          </a>
          <a
            href="#"
            className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
          >
            Projects
          </a>
          <a
            href="#"
            className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
          >
            Calendar
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
