import { useEffect, useMemo, useState } from "react";
import { Provider as AuthProvider, setOptions } from "next-auth/client";
import * as Sentry from "@sentry/node";
import { getSession } from "next-auth/client";
import { SWRConfig } from "swr";
import { TinaProvider, TinaCMS } from "tinacms";
import Layout from "../components/Layout";
import "tippy.js/dist/tippy.css";
import "../styles/index.css";
import App from "next/app";
import { useRouter } from "next/router";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const site = process.env.SITE || "http://localhost:3000";

setOptions({ site });

function RanticApp({ Component, pageProps, session }) {
  const router = useRouter();
  const { pathname } = router;
  // @ts-ignore
  const fetcher = (...args) => fetch(...args).then((res) => res.json());
  const isAdmin = session?.role === "ADMIN";
  const isEditable =
    pathname.startsWith("/services/") || pathname.startsWith("/pages/");
  // const isEditable = req
  // @ts-ignore
  const [{ HtmlFieldPlugin }, setEditor] = useState({});

  useEffect(() => {
    if (!HtmlFieldPlugin && isAdmin) {
      import("react-tinacms-editor").then(setEditor);
    }
  }, [isAdmin]);

  const cms = useMemo(
    () =>
      new TinaCMS({
        enabled: isAdmin,
        sidebar: isAdmin && isEditable,
        plugins: HtmlFieldPlugin ? [HtmlFieldPlugin] : null,
      }),
    [isAdmin, isEditable, HtmlFieldPlugin]
  );

  return (
    <AuthProvider session={pageProps.session} options={{ site }}>
      <SWRConfig
        value={{
          fetcher,
        }}
      >
        <TinaProvider cms={cms}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </TinaProvider>
      </SWRConfig>
    </AuthProvider>
  );
}

RanticApp.getInitialProps = async (context) => {
  const appProps = await App.getInitialProps(context);
  const session = await getSession(context);

  return {
    ...appProps,
    session,
  };
};

export default RanticApp;
